<template>
  <v-main transition="slide-x-transition" class="pt-0">
    <!-- navigation menu -->
    <navbar
      id="nav"
      v-if="
        $auth.isAuthenticated && $auth.userProfile && $auth.tenantProfile
      " />

    <!-- router-view will be replaced with whichever route is active -->
    <!-- style lets router view fill the height of the window if its height doesn't 
         reach the bottom, allowing the footer to be at the bottom of the screen. If 
         the router view is longer than the height, the footer will still be at the 
         bottom of the scrollable area -->
    <router-view
      style="-webkit-box-flex: 1; -ms-flex: 1 1 auto; flex: 1 1 auto"
      v-if="
        $auth.isAuthenticated && $auth.userProfile && $auth.tenantProfile
      " />

    <!-- footer -->
    <v-footer color="#004370" padless style="position: relative; bottom: 0%">
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="link in links"
          :key="link.label"
          :href="link.url"
          color="white"
          text
          rounded
          class="my-2">
          {{ link.label }}
        </v-btn>
      </v-row>
    </v-footer>
  </v-main>
</template>

<script>
  import navbar from '@/components/partials/nav.vue';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'home',
    components: {
      navbar,
    },
    data() {
      return {
        links: [
          {
            label: 'Privacy Policy',
            url: 'https://planitgeo.com/wp-content/uploads/2022/02/PlanIT-Geo-and-TreePlotter-Privacy-Policy-Feb-2022.pdf',
          },
          {
            label: 'Terms of Service',
            url: 'https://planitgeo.com/wp-content/uploads/2022/02/PlanIT-Geo-and-TreePlotter-Terms-of-Service-Feb-2022.pdf',
          },
          {
            label: 'JOBS Terms of Use',
            url: 'https://planitgeo.com/wp-content/uploads/2022/02/PlanIT-Geo-TreePlotter-Jobs-Terms-of-Use-Feb-2022.pdf',
          },
          {
            label: 'Platform, Security and Privacy FAQ',
            url: 'https://planitgeo.com/wp-content/uploads/2021/03/Platform-Security-and-Privacy-FAQ-PlanIT-Geo™-and-TreePlotter™-2021.pdf',
          },
        ],
      };
    },
    computed: {},
    methods: {},
  };
</script>

<style lang="scss">
  a {
    text-decoration: none;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 90%;
  }

  // the default height for the header in vuetify is 65px
  // by setting this here, the main wrapper can float the
  // router view for the rest of the webpage right under it
  // without having to guess at margins and padding.
  #nav {
    width: auto;
    height: 65px;
  }

  // tells the main wrapper to stack the navigation above the
  // router view
  .v-main__wrap {
    display: inline-flex;
    flex-direction: column;
  }
</style>
