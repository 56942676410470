<template>
  <span>
    <v-card>
      <v-navigation-drawer
        v-model="drawer"
        :expand-on-hover="$vuetify.breakpoint.mdAndUp"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :mini-variant.sync="mini"
        clipped
        app
        height="100vh">
        <v-divider />

        <v-list dense class="py-0">
          <v-list-item v-if="allowMyForest" key="MyForest" router to="/">
            <v-list-item-action>
              <v-icon>mdi-pine-tree</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> My Forest </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="allowClients" key="Clients" router to="/clients">
            <v-list-item-action>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Clients </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="allowWork"
            no-action
            prepend-icon="mdi-fire-truck"
            class="py-1">
            <template v-slot:activator>
              <v-list-item-title>Work</v-list-item-title>
            </template>

            <v-list-item v-if="allowJobs" key="Jobs" router to="/jobs">
              <v-list-item-action>
                <v-icon>mdi-briefcase-check-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Jobs </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="allowServiceRequests"
              key="Service Requests"
              router
              to="/serviceRequests"
              @click="drawer = false">
              <v-list-item-action>
                <v-icon>mdi-leaf</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Service Requests </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="allowEstimates"
              key="Estimates"
              router
              to="/estimates"
              @click="drawer = false">
              <v-list-item-action>
                <v-icon>mdi-format-list-numbered</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Estimates </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="allowWorkOrders"
              key="Work Orders"
              router
              to="/workorders"
              @click="drawer = false">
              <v-list-item-action>
                <v-icon>mdi-home-city-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Work Orders </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="allowInvoices"
              key="Invoices"
              router
              to="/invoices">
              <v-list-item-action>
                <v-icon>mdi-tag-text-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Invoices </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-if="allowSchedule"
            key="Schedule"
            router
            to="/scheduling">
            <v-list-item-action>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Schedule </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="allowAdmin" key="Tools" router to="/tools">
            <v-list-item-action>
              <v-icon>mdi-hand-saw</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Tools </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-if="allowAdmin"
            no-action
            prepend-icon="mdi-cog"
            class="py-1">
            <template v-slot:activator>
              <v-list-item-title>Admin</v-list-item-title>
            </template>

            <v-list-item
              v-if="allowTenants"
              key="Tenants"
              router
              to="/tenants"
              @click="drawer = false">
              <v-list-item-action>
                <v-icon>mdi-home-group</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Tenants </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="allowUsers"
              key="Users"
              router
              to="/users"
              @click="drawer = false">
              <v-list-item-action>
                <v-icon>mdi-account-multiple-plus</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Users </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="allowCatalog"
              key="Catalog"
              router
              to="/catalog"
              @click="drawer = false">
              <v-list-item-action>
                <v-icon>mdi-book-open-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Price Catalog </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="allowTenantSettings"
              key="Settings"
              router
              to="/settings"
              @click="drawer = false">
              <v-list-item-action>
                <v-icon>mdi-tune</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Settings </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="allowTenantSpecies"
              key="Species"
              router
              to="/species"
              @click="drawer = false">
              <v-list-item-action>
                <v-icon>mdi-tree</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Species </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="allowAdminSpecies"
              key="Species Admin"
              router
              to="/speciesAdmin"
              @click="drawer = false">
              <v-list-item-action>
                <v-icon>mdi-tree-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Species Admin </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
            v-if="allowSubscription && showPaymentInfo"
            prepend-icon="mdi-folder-account"
            no-action
            class="py-1">
            <template v-slot:activator>
              <v-list-item-title>Manage Subscription</v-list-item-title>
            </template>

            <v-list-item
              v-if="allowSubscription"
              key="Payment info"
              @click="customerPortal">
              <v-list-item-action>
                <v-icon>mdi-link</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Customer Portal</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
        <v-divider />
        <v-divider />
      </v-navigation-drawer>
    </v-card>

    <!-- Check that the SDK client is not currently loading before accessing is methods -->
    <!-- <div v-if="!$auth.loading"> -->

    <v-app-bar app color="primary" dark style="left: 0">
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
      <router-link to="/" class="">
        <v-img
          class="ma-2"
          max-height="48"
          max-width="140"
          contain
          :src="logo" />
      </router-link>
      <v-spacer class="hidden-md-and-up" />
      <v-spacer />
      <!-- TODO: enable with quickbooks rollout -->
      <!-- <v-btn
        v-show="
          quickbookStatus.enabled &&
          quickbookStatus.valid &&
          quickbookStatus.expires_in < 15
        "
        color="yellow"
        icon>
        <v-icon>mdi-alert</v-icon>
      </v-btn> 
      <v-btn
        v-show="quickbookStatus.enabled && !quickbookStatus.valid"
        color="red"
        icon>
        <v-icon>mdi-alert</v-icon>
      </v-btn>
      -->

      <v-menu v-if="$auth.isAuthenticated" offset-y>
        <!-- This looks for a named slot within the v-menu HTML and inserts the following HTML there. Also, the 'on' variable comes from the v-menu code and toggles the menu on and off -->
        <template v-slot:activator="{on}">
          <v-btn icon v-on="on">
            <v-badge v-model="badgeVisible" color="red">
              <!-- This is telling vue "look for the slot within the v-badge HTML named 'badge', and insert the following HTML there" -->
              <template v-slot:badge>
                <span>{{ numberOfUnseenNotifications || 0 }}</span>
              </template>

              <!-- Unnamed HTML will get inserted into the generic, unnamed slot in the v-badge HTML -->
              <v-icon @click="markNotificationsVisited"
                >mdi-bell-outline</v-icon
              >
            </v-badge>
          </v-btn>
        </template>
        <v-list v-if="notifications">
          <v-list-item
            v-for="notification in notifications"
            v-bind:key="notification"
            router
            class="notificationListItem">
            <a v-if="notification.link" :href="notification.link">
              <v-list-item-content>
                <v-list-item-title>{{
                  notification.content
                }}</v-list-item-title>
              </v-list-item-content>
            </a>
            <v-divider v-else />
          </v-list-item>
        </v-list>

        <v-layout row class="pl-3">
          <v-flex
            v-if="navUserProfile"
            id="viewAllNotifsLink"
            md12
            class="pa-3 white">
            <router-link :to="'/notifications'" class=""
              >View All Notifications</router-link
            >
          </v-flex>
        </v-layout>
      </v-menu>

      <div>
        <v-btn icon href="http://support.treeplotterjobs.com/" target="_blank">
          <v-icon>mdi-comment-question-outline</v-icon>
        </v-btn>
      </div>

      <div v-if="$auth.isAuthenticated">
        <v-btn text @click="logout">SIGN OUT</v-btn>
      </div>
      <div v-else>
        <v-btn text @click="login">SIGN IN</v-btn>
      </div>
    </v-app-bar>
  </span>
</template>

<script>
  import axios from 'axios';
  import configurations from '@/mixins/configurations';
  import System from '@/services/System.service.js';
  // TODO: enable with quickbooks rollout
  // import Tenants from '@/services/Tenants.service.js';

  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'navbar',
    mixins: [configurations],
    data() {
      return {
        errorDialog: false,
        errorHeader: '',
        errorBody: '',
        // TODO: enable with quickbooks rollout
        // quickbookStatus: {
        //   enabled: false,
        //   valid: false,
        //   expires_in: 0,
        // },
        paymentLink: '',
        mini: undefined,
        appTitle: 'Opus',
        drawer: null,
        //default permissions follow (refreshed when getAllowed method is called)
        //TODO - Should these just be computed properties instead? not sure because they depend on external methods :-(
        allowMyForest: true,
        allowSchedule: false,
        allowWork: false,
        allowJobs: false,
        allowWorkOrders: false,
        allowEstimates: false,
        allowServiceRequests: false,
        allowInvoices: false,
        allowAdmin: false,
        allowTenants: false,
        allowClients: false,
        allowUsers: false,
        allowCatalog: false,
        allowTenantSpecies: false,
        allowAdminSpecies: false,
        allowSubscription: false,
        logo: require('@/assets/tpLogo-color.png'),
        notifications: undefined,
        navUserProfile: undefined,
        numberOfUnseenNotifications: 0,
        subscription: {
          uuid: null,
          tenant_uuid: null,
          first_name: '',
          last_name: '',
          email: '',
          company: '',
          customer_id: '',
          subscription_id: '',
          product_code: '',
          plan_code: '',
          active: 'INACTIVE',
        },
      };
    },
    computed: {
      badgeVisible: function () {
        return this.numberOfUnseenNotifications > 0;
      },
      showPaymentInfo: function () {
        return true;
      },
    },
    mounted() {
      // console.log( "************************** test" )

      // this.navUserProfile = await this.$auth.userProfile

      this.$events.$on('RouteChanged', async () => {
        this.mini = true;
        setTimeout(() => {
          this.mini = undefined;
        }, 500);
      });

      // if ( this.navUserProfile ) {
      // //listen for login events, which may alter login status or permissions which require a re-render
      // this.$events.$on( 'LoginEvent', async () => {
      //   // console.log("Forcing a re-render");
      // this.getAllowed(); //refresh permissions
      // this.$forceUpdate(); //TODO - change this to just a component rerender instead of full refresh
      // } )

      this.getAllowed();

      //create notification event listener if we are already logged in
      this.setupNotificationsUpdatedHandler();

      // this asks to update notifications when mounted,
      //  the LoginEvent below handles the case where the
      //  users logs in and so the tenantID isn't ready here
      this.$events.$emit('NotificationsUpdated', {});

      // this.$events.$on( 'LoginEvent', async () => {
      //   //create notification event listener if user logs in
      //   this.setupNotificationsUpdatedHandler()
      //   this.$events.$emit( "NotificationsUpdated", {} )
      // } )
      // }
    },
    async created() {
      //fetches initial permission list
      // this.getAllowed();
    },
    methods: {
      // Log the user in
      login() {
        this.$auth.loginWithRedirect();
      },
      // Log the user out
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      },

      async markNotificationsVisited() {
        this.numberOfUnseenNotifications = 0;
        // console.log("heres the number of unseen event")
        // console.log(this.numberOfUnseenNotifications)

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();
        axios({
          method: 'PUT',
          url: '/service_notifications/notifications/update',
          data: {
            notificationUUIDs: this.notifications.slice(0, 10).map((x) => {
              return x.uuid;
            }),
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then(
          () => {
            // console.log("notifications marked as seen")
          },
          (e) => {
            throw Error('Something went wrong', e);
          }
        );
      },
      async setupNotificationsUpdatedHandler() {
        //TLC only create event listener if we have a tenant uuid key
        let tenant_uuid = await this.$auth.userProfile.tenant_uuid;
        if (!tenant_uuid) {
          return;
        }

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        //update quickbooks status
        // TODO: enable with quickbooks rollout
        // let qbStatus = await Tenants.getQuickbooksTokenStatus(
        //   this.$auth.userProfile.tenant_uuid,
        //   accessToken
        // );

        // TODO: enable with quickbooks rollout
        // if (qbStatus) { this.quickbookStatus = qbStatus; }

        //create notification event listener
        this.$events.$on('NotificationsUpdated', async () => {
          var notificationResults = await axios({
            method: 'GET',
            url: '/service_notifications/notifications/all',
            params: {
              tenant_uuid: tenant_uuid,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (notificationResults) {
            // reverse sort notifications by created
            this.notifications = notificationResults.data
              .sort((x, y) => {
                return Date.parse(y.created) - Date.parse(x.created);
                //this generates the date from the created field in the format "1/25"
                // obj.created.split("T")[0].split("-").slice(1,3).map(x=>parseInt(x)).join("/")
              })
              .slice(0, 10);

            this.numberOfUnseenNotifications = 0;
            this.notifications.forEach((notification) => {
              if (!notification.beenSeen) {
                this.numberOfUnseenNotifications++;
              }
            });
          }
        });
      },
      customerPortal: async function () {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // get tenant uuid
        let tenantID = this.$auth.userProfile.tenant_uuid;

        if (tenantID) {
          let res = await System.loadSubscriptionByTenantUUID(
            tenantID,
            accessToken
          );
          if (res) {
            let redirectURL = await System.createCustomerPortalSession(
              {customer_id: res.customer_id},
              accessToken
            );
            if (redirectURL) {
              window.location.replace(redirectURL);
            }
          }
        }
      },

      async getAllowed() {
        // updates our permission variables
        if (this.$auth.userProfile && this.$auth.userProfile.role) {
          this.allowMyForest = this.$auth.isAllowed('myForest', 'view');
          this.allowWorkOrders = this.$auth.isAllowed('workorders', 'list');
          this.allowSchedule = this.$auth.isAllowed('schedules', 'view');
          this.allowInvoices = this.$auth.isAllowed('invoices', 'list');
          this.allowTenants = this.$auth.isAllowed('tenants', 'list');
          this.allowEstimates = this.$auth.isAllowed('estimates', 'list');
          this.allowServiceRequests = this.$auth.isAllowed(
            'servicerequests',
            'list'
          );
          this.allowClients = this.$auth.isAllowed('clients', 'list');
          this.allowJobs = this.$auth.isAllowed('jobs', 'list');
          this.allowUsers = this.$auth.isAllowed('users', 'list');
          this.allowSpeciesManage = this.$auth.isAllowed('species', 'list');
          this.allowCatalog = this.$auth.isAllowed('catalog', 'view');
          this.allowSubscription = this.$auth.isAllowed('subscription', 'edit');
          this.allowTenantSettings = this.$auth.isAllowed(
            'tenantsettings',
            'edit'
          );
          this.allowUploadClientRecords = this.$auth.isAllowed(
            'tools',
            'uploadClientRecords'
          );

          //admin group will only show if user has access to at least one sub item
          this.allowAdmin =
            this.allowTenants || this.allowUsers || this.allowCatalog;

          //work group will only show if user has access to at least one sub item
          this.allowWork = true;

          //TEMPORARY ASSIGNMENTS
          this.allowTenantSpecies = this.$auth.isAllowed('species', 'edit');
          this.allowAdminSpecies = this.$auth.isAllowed('speciesadmin', 'edit');
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .v-toolbar__content .v-btn {
    color: snow !important;
  }
  .v-list__group__header__prepend-icon.primary--text {
    background-color: transparent;
  }
  .notificationListItem {
    transition: background-color 0.5s ease;
    border-bottom: 1px solid lightgray;
  }

  .notificationListItem:hover {
    background-color: lightgray;
  }

  #viewAllNotifsLink {
    border-top: 3px solid lightgray;
  }
</style>
